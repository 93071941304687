import React from "react";
import { Link } from "react-router-dom";
import whiteLogo from "../../../javascript/assets/images/Safetyloop-logo-white.svg";

const LoginFooter = () => {
  return (
    <div className="login-footer row d-flex justify-content-between">
      <div className="home-container">
        <div className="col-xs-12 col-md-6 col-xl-6 terms-privacy">
          
          <a href="/terms">Terms & Conditions</a>
          &nbsp;|
          <a href="/privacy">Privacy Policy</a>
          &nbsp;|
          <a href="/disclaimer">Disclaimer</a>
          &nbsp;|
          <a href="/eula">EULA</a>
          {/* <Link to="/terms" role="button">Terms & Condtions.</Link>
          <Link to="/privacy" role="button">Privacy Policy</Link> */}
        </div>
        <div className="copyright col-xs-12 col-md-6 col-xl-6">
        <Link to="/" role="button"><img src={whiteLogo} className="login-footer-img" /></Link>
          <p>
          Copyright 2024 © SafetyLoop, Inc. All rights reserved. Version 2.6.1
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginFooter;
